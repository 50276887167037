<template>
  <d-confirm-dialog
    :title="`Delete ${recipient.address_type}`"
    :detail="`Do you really want to delete ${recipient.name}?`"
    :value="value"
    :status="apiResult.status"
    :error="apiResult.error"
    @confirm="confirm"
    @cancel="close"
  />
</template>
<script>

const props = {
  value: {
    type: Boolean,
    default: false
  },
  recipient: {
    type: Object,
    default: () => ({
      address_type: '',
      name: ''
    })
  }
}

export default {
  name: 'DeleteRecipientDialog',
  props,
  data() {
    return {
      apiResult: {
        status: null,
        error: null
      }
    }
  },
  methods: {
    async confirm() {
      const { status, error } = await this.$store.dispatch(
        'users/DELETE_RECIPIENT',
        this.recipient
      )

      this.apiResult.status = status
      this.apiResult.error = error

      setTimeout(() => {
        if (!error) {
          this.close()
        }
        this.successful = null
      }, 500)
    },
    close() {
      this.$emit('onClose')
    }
  }
}
</script>
