<template>
  <v-container fluid fill-height>
    <v-layout row wrap>
      <v-flex xs12>
        <v-tabs dark slider-color="primary" v-model="active">
          <v-tab ripple v-for="tab in tabs" :key="tab.id">
            {{ tab.label }}
          </v-tab>
          <v-tab-item v-for="tab in tabs" :key="tab.id" :data-testid="tab.id">
            <component :is="tab.component" :offline="instanceOffline" />
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import NotificationCard from '@/components/User/Notifications/NotificationsCard'
import AlertSettingsCard from '../components/User/Notifications/AlertSettingsCard'

export default {
  name: 'NotificationPage',
  data: () => ({
    active: null
  }),
  computed: {
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('users', ['status', 'error']),
    ...mapGetters('sites', ['activeSiteId']),
    ...mapState(['offline']),
    instanceOffline() {
      return this.offline
    },
    tabs() {
      const tabs = []
      tabs.push({
        label: 'Alert Settings',
        id: 'alert_settings',
        component: AlertSettingsCard
      })

      if (!this.instanceOffline) {
        tabs.push({
          label: 'Alert Recipients',
          id: 'alert_recipients',
          component: NotificationCard
        })
      }
      return tabs
    }
  },
  methods: {},
  async mounted() {
    if (this.isAuthorized('site_manager')) {
      await this.$store.dispatch('users/FETCH_RECIPIENTS', this.activeSiteId)
    }
  },
  watch: {
    async activeSiteId() {
      if (this.isAuthorized('site_manager')) {
        await this.$store.dispatch('users/FETCH_RECIPIENTS', this.activeSiteId)
      }
    }
  }
}
</script>
