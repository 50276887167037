<template>
  <base-dialog-2
    title="Add Recipient"
    :value="value"
    :status="apiResult.status"
    :error="apiResult.error"
    :valid-form="valid"
    confirmOnceStatus="OK"
    @onConfirm="confirm"
    @onClose="close"
    @onValidateForm="validateForm"
  >
    <template v-slot:form>
      <v-text-field
        color="primary"
        name="name"
        label="Name"
        v-model="name"
        :rules="[rules.required]"
        required
      ></v-text-field>

      <v-select label="Type" v-model="address_type" :items="['SMS', 'Email']" />
      <v-text-field
        v-if="address_type === 'Email'"
        color="primary"
        name="address"
        label="Email Address"
        :rules="rules.emailRules"
        type="email"
        v-model="address"
        required
      ></v-text-field>
      <template v-if="address_type === 'SMS'">
        <vue-phone-number-input
          v-model="phone"
          :dark="true"
          color="#00000000"
          valid-color="#00000000"
          size="lg"
          class="px-0 my-3"
          :ignored-countries="bannedCountries"
          @update="phoneUpdate"
        />
        <v-text-field
          v-show="false"
          color="primary"
          name="test"
          label="test"
          v-model="validNumber"
          :rules="[v => v || 'Invalid phone number']"
          required
        ></v-text-field>
      </template>
      <v-tooltip
        :open-delay="tooltipDelay"
        color="sdark"
        max-width="430"
        right
      >
        <v-select
          slot="activator"
          v-model="zone_ids"
          :items="alertZonesList"
          label="Alert Zones"
          item-text="name"
          item-value="id"
          class="pt-3"
          multiple
        ></v-select>
        <span>
          Alert zones which recipients will receive a notification for
        </span>
      </v-tooltip>
      <v-tooltip
        right
        nudge-bottom="-10"
        max-width="285"
        color="sdark"
        :open-delay="tooltipDelay"
      >
        <v-switch
          slot="activator"
          class="py-0"
          label="Offline Sensors Alerts"
          v-model="sensors_down"
          color="primary"
          type="Boolean"
        />
        <span>
          Recipient will receive a sensor down notification when enabled
        </span>
      </v-tooltip>
    </template>
  </base-dialog-2>
</template>

<script>
import { BaseDialog2 } from '@/components/Base'
import { FormMixin } from '@/components/Mixins'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { mapGetters } from 'vuex'

const props = {
  value: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: null
  }
}

export default {
  name: 'AddRecipientDialog',
  components: {
    BaseDialog2,
    VuePhoneNumberInput
  },
  mixins: [FormMixin],
  props,
  data: () => ({
    valid: null,
    name: null,
    address: null,
    address_type: 'email',
    phone: null,
    e164number: null,
    validNumber: false,
    zone_ids: [],
    sensors_down: false,
    apiResult: {
      status: null,
      error: null
    }
  }),
  computed: {
    ...mapGetters('zones', ['alertZonesList']),
  },
  methods: {
    validateForm(value) {
      this.valid = value
    },
    close() {
      this.$emit('onClose')
    },
    async confirm() {
      const { name, zone_ids, sensors_down } = this
      const address_type = this.address_type.toLowerCase()
      const address = this.address || this.e164number
      const { status, error } = await this.$store.dispatch(
        'users/CREATE_RECIPIENT',
        {
          name,
          address,
          address_type,
          zone_ids,
          sensors_down
        }
      )
      this.apiResult.status = status
      this.apiResult.error = error

      setTimeout(() => {
        if (this.apiResult.status === 'OK') {
          this.close()
        }
        this.apiResult.status = null
      }, 1000)
    },
    phoneUpdate(e) {
      this.e164number = e.e164
      this.validNumber = e.isValid
    }
  },
  watch: {
    validNumber(v) {
      let color = v ? '#b3ffffff' : '#ff5252'
      for (let border of document.querySelectorAll('.vue-phone-number-input'))
        border.style.borderColor = color
    },
    address_type() {
      this.address = null
      this.phone = null
    }
  }
}
</script>

<style>
.field .field-input {
  height: 32px !important;
  min-height: 32px !important;
}
.select-country-container .field-label {
  display: none !important;
}
.country-selector .field-input {
  padding-top: 3px !important;
}
.country-selector .field-country-flag {
  top: 12px !important;
}
.country-selector .country-list {
  max-height: 140px !important;
}
.vue-phone-number-input .field-input {
  border-color: #00000000 !important;
}
.input-phone-number .field-label {
  color: #b3ffffff !important;
  font-size: 12px !important;
  top: -3px !important;
}
.vue-phone-number-input .select-country-container {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
</style>
<style scoped>
.vue-phone-number-input {
  border-bottom: thin solid #ff5252;
}
</style>
