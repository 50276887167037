




























































































































































import Vue from 'vue'
import pick from 'lodash/pick'
import cloneDeep from "lodash/cloneDeep";
import { mapActions, mapGetters } from 'vuex'
import {AUTO_JAM_AND_ZONE_TRIGGER_PROBABILITIES} from "@/store/modules/sites/types";
import { FormMixin } from '@/components/Mixins'

const VALID_SETTING_KEYS = [
  'zone_trigger_probability',
  'zone_trigger_detection_count',
  'alert_suppression_time_window',
  'trigger_sensors_offline_alerts'
]

const props = {
  offline: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'AlertSettingsCard',
  mixins: [FormMixin],
  props,
  data: (): any => {
    return {
      showError: false,
      showUpdated: false,
      tooltipDelay: 500,
      siteData: {},
      zoneTriggers: [],
      loading: false,
      selectedZoneTriggers: [],
      toneHeaders: [
        {
          label: 'Ring Alert',
          value: 'ringAlert'
        },
        {
          label: 'Human Voice Alert',
          value: 'humanVoice'
        },
        {
          label: 'Upload .wav File',
          value: 'uploadWav'
        }
      ],
      wavFileName: '',
      toneChoice: '',
      audioAlertsEnabled: false,
      audioVolume: 0
    }
  },
  mounted() {
    this.siteData = cloneDeep(this.activeSite)
  },
  methods: {
    ...mapActions('sites', {
      updateSiteAlertSettings: 'UPDATE_SITE_ALERT_SETTINGS',
      clearStatus: 'CLEAR_STATUS'
    }),
    async saveAlertSettings() {
      this.showError = false
      this.loading = true

      await this.updateSiteAlertSettings({
        ...pick(this.siteData, VALID_SETTING_KEYS),
        id: this.siteData.id
      })

      this.loading = false
      if (this.status === 'OK') {
        this.showUpdated = true
        setTimeout(() => {
          this.clearStatus()
        }, 1500)
        setTimeout(() => {
          this.showUpdated = false
        }, 2500)
      } else {
        this.showError = true
      }
    },
    uploadFile() {
      this.wavFileName = this.$refs.audioUpload.files[0].name
    }
  },
  computed: {
    ...mapGetters('sites', [
      'activeSiteId',
      'sitesList',
      'sitesSet',
      'activeSite',
      'status',
      'error'
    ]),
    allProbabilities() {
      return AUTO_JAM_AND_ZONE_TRIGGER_PROBABILITIES
    },
    zone_trigger_probability: {
      get(): number {
        return this.siteData['zone_trigger_probability'] || 0
      },
      set(prob: number) {
        this.siteData['zone_trigger_probability'] = prob
      }
    },
    audioAlertsToggleLabel() {
      if (this.audioAlertsEnabled) {
        return 'Audio Alerts Enabled'
      }
      return 'Audio Alerts Disabled'
    },
    uploadButtonDisabled() {
      if (this.toneChoice === 'uploadWav' && this.audioAlertsEnabled) {
        return false
      }
      return true
    }
  }
})
