<template>
  <base-dialog-2
    title="Edit recipient"
    :value="value"
    :status="apiResult.status"
    :error="apiResult.error"
    :valid-form="valid"
    confirmOnceStatus="OK"
    @onConfirm="confirm"
    @onClose="close"
    @onValidateForm="validateForm"
  >
    <template v-slot:form>
      <v-text-field
        color="primary"
        name="name"
        label="Name"
        v-model="recipientCopy.name"
        :rules="[rules.required]"
        required
      ></v-text-field>

      <v-tooltip bottom max-width="450" color="sdark" nudge-bottom="-25">
        <v-select
          slot="activator"
          label="Type"
          v-model="recipientCopy.address_type"
          :items="['sms', 'email']"
          disabled
        />
        <span
          >Alert's type cannot be edited, please create a new alert if
          needed</span
        >
      </v-tooltip>
      <v-text-field
        v-if="recipientCopy.address_type === 'email'"
        color="primary"
        name="address"
        label="Email"
        :rules="rules.emailRules"
        v-model="recipientCopy.address"
        required
      ></v-text-field>
      <template v-else>
        <vue-phone-number-input
          v-model="phone"
          :key="`phone-${recipient.name}`"
          :dark="true"
          color="#00000000"
          valid-color="#00000000"
          size="lg"
          class="px-0 my-3"
          :no-use-browser-locale="true"
          :default-country-code="countryCode"
          :ignored-countries="bannedCountries"
          @update="phoneUpdate"
        />
        <v-text-field
          v-show="false"
          color="primary"
          name="test"
          label="test"
          v-model="validNumber"
          :rules="[v => v || 'Invalid phone number']"
          required
        ></v-text-field>
      </template>
      <v-tooltip
        :open-delay="tooltipDelay"
        color="sdark"
        max-width="430"
        right
      >
        <v-select
          slot="activator"
          v-model="recipientCopy.zone_ids"
          :items="alertZonesList"
          label="Alert Zones"
          item-text="name"
          item-value="id"
          class="pt-3"
          multiple
        ></v-select>
        <span>
          List of Alert zones that will trigger an alert for this recipient
        </span>
      </v-tooltip>
      <v-tooltip
        right
        nudge-bottom="-10"
        max-width="285"
        color="sdark"
        :open-delay="tooltipDelay"
      >
        <v-switch
          slot="activator"
          class="py-0"
          label="Offline Sensors Alerts"
          v-model="recipientCopy.sensors_down"
          color="primary"
        />
        <span>
          Recipient will receive a notification when sensor is offline for at least 15 minutes
        </span>
      </v-tooltip>
    </template>
  </base-dialog-2>
</template>
<script>
import { BaseDialog2 } from '@/components/Base'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { FormMixin } from '@/components/Mixins'
import { mapGetters } from 'vuex'
const VuePhoneNumberInput = () => import('vue-phone-number-input')

const props = {
  value: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  },
  recipient: {
    type: Object,
    default: () => ({
      address_type: '',
      name: ''
    })
  }
}

export default {
  name: 'EditRecipientDialog',
  mixins: [FormMixin],
  props: props,
  components: {
    BaseDialog2,
    VuePhoneNumberInput
  },
  data: () => ({
    valid: null,
    successful: null,
    recipientCopy: {
      address_type: '',
      address: '',
      name: '',
      id: 0
    },
    phone: null,
    validNumber: true,
    countryCode: null,
    apiResult: {
      status: null,
      error: null
    }
  }),
  computed: {
    ...mapGetters('zones', ['alertZonesList']),
  },
  methods: {
    validateForm(value) {
      this.valid = value
    },
    close() {
      this.$emit('onClose')
    },
    async confirm() {
      const { status, error } = await this.$store.dispatch(
        'users/UPDATE_RECIPIENT',
        this.recipientCopy
      )
      this.apiResult.status = status
      this.apiResult.error = error

      setTimeout(() => {
        if (this.successful) {
          this.close()
        }
        this.successful = null
      }, 1000)
    },
    phoneUpdate(e) {
      this.recipientCopy.address = e.e164
      this.validNumber = e.isValid
    }
  },
  watch: {
    validNumber(v) {
      let color = v ? '#b3ffffff' : '#ff5252'
      for (let border of document.querySelectorAll('.vue-phone-number-input'))
        border.style.borderColor = color
    },
    recipient: {
      handler: function(v) {
        this.recipientCopy = JSON.parse(JSON.stringify(v))
        if (this.recipientCopy.address_type === 'sms') {
          const phoneNumber = parsePhoneNumberFromString(
            this.recipientCopy.address
          )
          this.countryCode = phoneNumber.country
          this.phone = phoneNumber.formatNational()
          this.address = this.recipientCopy.address
          this.validNumber = true
        }
      },
      immediate: true
    }
  }
}
</script>
<style scoped>
.vue-phone-number-input {
  border-bottom: thin solid #b3ffffff;
}
</style>
