<template>
  <v-card>
    <add-recipient-dialog
      v-if="addDialog"
      :value="addDialog"
      :status="status"
      @onClose="onCloseDialog"
    ></add-recipient-dialog>
    <delete-recipient-dialog
      v-if="deleteDialog"
      :value="deleteDialog"
      :status="status"
      :recipient="activeRecipient"
      @onClose="onCloseDialog"
    ></delete-recipient-dialog>
    <edit-recipient-dialog
      v-if="editDialog"
      :value="editDialog"
      :recipient="activeRecipient"
      :status="status"
      @onClose="onCloseDialog"
    ></edit-recipient-dialog>
    <v-card-actions>
      <v-btn flat @click="addDialog = true">
        Add Recipient
        <v-icon right>notifications</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="headersRecep"
        :items="recipientsList"
        hide-actions
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.address }}</td>
          <td>{{ zonesNames(props.item.zone_ids) }}</td>
          <td>
            <v-icon v-if="props.item.sensors_down">done</v-icon>
            <div v-else>-</div>
          </td>
          <td>
            <v-icon>{{ props.item.address_type }}</v-icon>
          </td>
          <td>
            <v-tooltip right color="sdark">
              <v-btn
                slot="activator"
                icon
                class="mx-0"
                @click="onClickEdit(props.item)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
              <span>Edit {{ props.item.name }} recipient</span>
            </v-tooltip>
            <v-tooltip right color="sdark">
              <v-btn
                slot="activator"
                icon
                class="mx-0"
                @click="onClickDelete(props.item)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
              <span>Delete {{ props.item.name }} recipient</span>
            </v-tooltip>
          </td>
          <td>
            <v-tooltip right color="sdark">
              <v-btn
                slot="activator"
                icon
                class="mx-0"
                @click="testRecipient(props.item)"
                :disabled="!props.item.enabled"
              >
                <v-icon>send</v-icon>
              </v-btn>
              <span>
                Sent test {{ props.item.type }} to
                {{ props.item.name }} recipient
              </span>
            </v-tooltip>
          </td>
          <td>
            <v-switch
              style="position:relative; top:12px"
              v-model="props.item.enabled"
              @click="enable(props.item.id)"
              color="primary"
              type="Boolean"
              :disabled="status == 'LOADING'"
            />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

import AddRecipientDialog from './AddRecipientDialog'
import EditRecipientDialog from './EditRecipientDialog'
import DeleteRecipientDialog from './DeleteRecipientDialog'

export default {
  name: 'NotificationsManagementCard',
  components: {
    AddRecipientDialog,
    EditRecipientDialog,
    DeleteRecipientDialog
  },
  data: () => ({
    addDialog: false,
    editDialog: false,
    deleteDialog: false,
    activeRecipient: undefined,
    headersRecep: [
      { text: 'Name', value: 'name', align: 'left' },
      { text: 'Address', value: 'address' },
      { text: 'Alert Zones', value: 'zone_ids', sortable: false },
      { text: 'Offline Sensor Update', value: 'sensors_down', sortable: false },
      { text: 'Type', value: 'address_type', sortable: false },
      { text: 'Action', value: 'action', sortable: false },
      { text: 'Test', value: 'test', sortable: false },
      { text: 'Enable', value: 'enabled', sortable: false }
    ]
  }),
  async mounted() {
    await this.clearStatusError()
  },
  computed: {
    ...mapGetters('users', ['recipientsList']),
    ...mapState('users', ['error', 'status']),
    ...mapGetters('zones', ['alertZonesList'])
  },
  methods: {
    ...mapActions('users', ['DISMISS']),
    async onCloseDialog() {
      this.addDialog = false
      this.editDialog = false
      this.deleteDialog = false
      await this.clearStatusError()
    },
    async clearStatusError() {
      this.DISMISS()
    },
    onClickEdit(recipient) {
      this.activeRecipient = Object.assign({}, recipient)
      this.editDialog = true
    },
    onClickDelete(recipient) {
      this.activeRecipient = Object.assign({}, recipient)
      this.deleteDialog = true
    },
    onRecipientUpdated(recipient) {
      this.activeRecipient = Object.assign({}, recipient)
    },
    enable(id) {
      const recipient = this.recipientsList.find(r => r.id == id)
      this.$store.dispatch('users/UPDATE_RECIPIENT', {
        ...recipient,
        enabled: !recipient.enabled
      })
    },
    zonesNames(zoneIDs) {
      return this.alertZonesList.filter(z => (zoneIDs || []).includes(z.id))
        .map(z => z.name)
        .join(', ') || '-'
    },
    async testRecipient(user) {
      await this.$store.dispatch('users/TEST_RECIPIENT', user)
      if (this.error) {
        this.$bus.$emit('APP_MESSAGE', {
          flush: 'manual',
          color: 'error',
          title: 'Error Sending a Test notification',
          message: `Reason: ${this.error}`
        })
      }
    }
  }
}
</script>
